<template>
  <BaseCard
    class="newsletter-card"
    :padding="{ mobile: 48, tablet: 64, desktop: 64 }"
  >
    <form
      v-if="!isSuccessfullySent && !isFailed"
      class="newsletter-card__form"
      novalidate
    >
      <h3 class="newsletter-card__heading">Sign up for our newsletter</h3>
      <FormBaseInput
        v-model="state.email"
        class="newsletter-card__input"
        placeholder="Enter email"
        :disabled="isLoading"
        type="email"
        name="email"
        required
        :v="v$.email"
      />
      <FormBaseCheckbox
        v-model="state.consentToProcess"
        class="newsletter-card__checkbox"
        label-i18-path="common.form.consents.unified_consent"
        name="consent-to-send-newsletter"
        required
        :v="v$.consentToProcess"
      >
        <nuxt-link to="/privacy-policy">
          {{ $t('common.form.privacy_policy') }}
        </nuxt-link>
      </FormBaseCheckbox>
      <BaseButton
        title="Subscribe"
        class="newsletter-card__submit-button"
        type="submit"
        @click.prevent="onSubmit"
      >
        <template #text>
          <span v-if="!isLoading">Subscribe</span>
          <LoaderSpinner white v-else class="newsletter-card__loader" />
        </template>
      </BaseButton>
    </form>
    <div
      v-if="isSuccessfullySent && !isFailed"
      class="newsletter-card__thank-you"
    >
      <h3 class="newsletter-card__thank-you-heading">Welcome on board!</h3>
      <p class="newsletter-card__thank-you-text">
        Thanks for subscribing to our newsletter. Our next update will reach
        your mailbox soon.
      </p>
    </div>
    <div v-if="isFailed" class="newsletter-card__error">
      <p>Something went wrong, please try again</p>
    </div>
    <BaseNuxtImage
      width="300"
      height="300"
      class="newsletter-card__thank-you-picture"
      alt="Illustration of a pigeon holding a letter"
      src="https://a.storyblok.com/f/120667/410x410/fc93bfd0ce/thank-you-pigeon-2x.png"
    />
  </BaseCard>
</template>

<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core'
import useSendData from '@/composables/useSendData'
import useValidation from '@/composables/useValidation'
import useGtm from '@/composables/useGtm'
import { getCookie } from '@/utils/helpers'

const { t } = useI18n()
const route = useRoute()

const router = useRouter()

const state = reactive({
  email: '',
  consentToProcess: false,
})

const { isFieldRequired, isEmailValid, isEmailBusiness, isConsentAccepted } =
  useValidation()

const rules = {
  email: {
    required: isFieldRequired,
    email: isEmailValid,
    business: isEmailBusiness,
  },
  consentToProcess: {
    accepted: isConsentAccepted,
  },
}

const { isFailed, isLoading, isSuccessfullySent, scrollToError, sendFormData } =
  useSendData()

const v$ = useVuelidate(rules, state)
const { userId, companyId } = useGtm(state, v$.value.$errors)

const isFormValid = computed(() => {
  return !v$.value.$invalid
})

const onSubmit = async () => {
  v$.value.$touch()

  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        name: 'email',
        value: state.email,
      },
      {
        name: 'user_id__infermedica_',
        value: userId(state.email),
      },
      {
        name: 'company_id__infermedica_',
        value: companyId(state.email),
      },
      {
        name: 'user_s_origin',
        value: router.options.history.state.back || '',
      },
    ],
    context: {
      hutk: getCookie('hubspotutk'),
      pageUri: window.location.href,
      pageName: route.name,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: state.consentToProcess,
        text: t('common.form.consents.newsletter_consent'),
        communications: [
          {
            value: state.consentToProcess,
            subscriptionTypeId: 9760346,
            text: t('common.form.consents.newsletter_consent'),
          },
        ],
      },
    },
  }

  if (isFormValid.value) {
    await sendFormData(
      'https://api.hsforms.com/submissions/v3/integration/submit/5731513/f5d80826-8b4b-4404-a849-6a1a0f0e83b1',
      data,
      {
        thankYouRoute: '',
        offset: 200,
      },
    )

    return
  }
  await nextTick()

  scrollToError()
}
</script>

<style lang="scss" scoped>
@keyframes thank-you-fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.newsletter-card {
  @include shadow-large-soft;

  width: 100% !important;
  align-items: flex-start;
  flex-direction: column-reverse;

  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
  }

  &__heading {
    margin-bottom: 32px;
  }

  &__form {
    flex-grow: 1;
    max-width: 436px;
    margin-left: auto;
    margin-right: auto;
  }

  &__loader {
    width: 24px;
    height: 24px;
    transform: scale(0.5) translateX(-100%) translateY(-100%);
  }

  &__input {
    margin-bottom: 32px;
  }

  &__checkbox {
    margin-bottom: 32px;
  }

  &__submit-button {
    width: 100% !important;
    margin-bottom: 32px;
  }

  &__thank-you {
    animation: thank-you-fade-in 0.8s;

    @media (min-width: $breakpoint-md) {
      padding-top: 80px;
    }
  }

  &__thank-you-text {
    max-width: 436px;
  }

  &__thank-you-heading-wrapper {
    position: relative;
    left: -14px;
    display: flex;
    align-items: center;
  }

  &__thank-you-heading {
    margin-bottom: 0;
    position: relative;
  }

  &__thank-you-picture {
    width: 100%;
    height: auto;
    max-width: 300px;
    margin: 0 auto 56px;
    flex-shrink: 1;

    @media (min-width: $breakpoint-lg) {
      margin: 16px 10px 0 auto;
    }
  }

  &__thank-you-check {
    margin: 0;
  }
}
</style>
